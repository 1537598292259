import { HiOutlineInformationCircle } from 'react-icons/hi'

import {
  FormControl as ChakraFormControl,
  FormControlOptions,
  FormErrorMessage,
  FormLabel,
  HStack,
  Icon,
  Tooltip,
} from '@chakra-ui/react'
import styled from '@emotion/styled'

import { Input } from '../Input'

type StyledFormInputControlTProps = {
  isV2?: boolean
}

export const StyledFormInputControl = styled(
  ChakraFormControl
)<StyledFormInputControlTProps>`
  .chakra-form__label {
    font-size: ${({ isV2 }) => (isV2 ? '12px' : '16px')};
    color: ${({ isV2 }) => (isV2 ? '#000000' : '#2d2e41')};
    font-weight: ${({ isV2 }) => (isV2 ? 400 : 500)};
    line-height: ${({ isV2 }) => (isV2 ? '18px' : '24px')};
    opacity: ${({ isV2 }) => (isV2 ? 1.0 : 0.5)};
    letter-spacing: ${({ isV2 }) => (isV2 ? '0px' : '-0.8px')};
  }
`

export interface FormInputControlIProps extends FormControlOptions {
  /**
   * The label text used to inform users as to what information is
   * requested for a text field.
   */
  label?: string
  /**
   * The custom `id` to use for the form control. This is passed directly to the form element (e.g, Input).
   * - The form element (e.g Input) gets the `id`
   * - The form label id: `form-label-${id}`
   * - The form error text id: `form-error-text-${id}`
   * - The form helper text id: `form-helper-text-${id}`
   */
  id?: string
  /**
   * The value for input type.
   */
  type?: string
  /**
   * If `true`, the input will display valid state styles with check icon.
   */
  valid?: boolean
  /**
   * The error message to display when `isInvalid` is `true`.
   */
  errorMessage?: string
  /**
   * The width of the input element.
   */
  width?: string
  /**
   * The `ref` is the `register()` from `react-hook-form`.
   */
  inputRef?: unknown
  /**
   * If added, the button will show an icon to the left of the input.
   */
  leftIcon?: React.ElementType
  /**
   * If `true`, the input will display error state styles.
   */
  isInvalid?: boolean
  /**
   * The placeholder value for input field.
   */
  placeholder?: string
  /**
   * The form label color.
   */
  labelColor?: string
  /**
   * The form label opacity.
   */
  labelOpacity?: number | string
  /**
   * The form label font size.
   */
  labelFontSize?: number | string
  /**
   * The form tooltip label, also used to show tooltip.
   */
  tooltipText?: string
  [key: string]: unknown
  variant?: 'outline' | 'v2'
}

export const FormInputControl = ({
  id,
  type = 'text',
  label,
  isInvalid = false,
  valid = false,
  errorMessage,
  width = '100%',
  labelColor = '#2d2e41',
  labelOpacity = 0.5,
  placeholder,
  leftIcon,
  inputRef,
  tooltipText,
  variant,
  ...rest
}: FormInputControlIProps) => {
  return (
    <StyledFormInputControl
      data-testid='form-control'
      id={id}
      isInvalid={isInvalid}
      isV2={variant === 'v2'}
      width={width}
    >
      {label && (
        <HStack alignItems='flex-start'>
          <FormLabel
            color={labelColor}
            data-testid='form-control-label'
            htmlFor={id}
            mr='0'
            opacity={labelOpacity}
            variant={variant}
          >
            {label}
          </FormLabel>
          {tooltipText && (
            <Tooltip label={tooltipText} placement='right' shouldWrapChildren>
              <Icon
                as={HiOutlineInformationCircle}
                boxSize='16px'
                color='#33344B'
              />
            </Tooltip>
          )}
        </HStack>
      )}
      <Input
        inputRef={inputRef}
        leftIcon={leftIcon}
        name={id}
        placeholder={placeholder}
        type={type}
        valid={valid}
        variant={variant}
        {...rest}
      />
      <FormErrorMessage data-testid='form-control-error'>
        {errorMessage}
      </FormErrorMessage>
    </StyledFormInputControl>
  )
}
